import { Component, OnInit } from '@angular/core';
import { redeemCodeAppliedOptions } from '../../lottie-config';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-redeem-code-success',
  template: `
    <div class="redeem-code-applied">
      <div class="redeem-code-applied-size">
        <ng-lottie [options]="redeemCodeAppliedOptions"></ng-lottie>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class RedeemCodeSuccessComponent implements OnInit {

  redeemCodeAppliedOptions: AnimationOptions = redeemCodeAppliedOptions;

  constructor() { }

  ngOnInit(): void {
  }

}
