import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CatlougeTypeConstants } from "src/app/core/constants";
import { UserService } from "src/app/core/services/user.service";
import { AssetsService } from "../../core/services/assets.service";
import { AccountService } from '../account/account.service';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
declare var $: any;
@Component({
  selector: 'app-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.css']
})
export class VouchersComponent implements OnInit {
  public sessionId: any;
  public isSession: boolean;
  public vouchersList: any;
  public notFoundImg: any;
  public some_error: any;
  public registeredBy: any;
  public registredEmail: any;
  public registredMobileNumber: any;
  public name:any;
  public id:any;
  public CurrentVoucherId: any;
  public getCodeData: any;
  public vCode: any;
  constructor(public activatedRoute: ActivatedRoute, 
              private modalService: NgbModal, 
              private userService: UserService, 
              private assetsService: AssetsService,
              private apiService: AccountService,
              private googleEvent: EventTrackingService,
              private router: Router) { }

  ngOnInit(): void {

    this.notFoundImg = 'square';
    this.activatedRoute.params.subscribe(params => {

      this.sessionId = params['sessionId'];
      if (params['sessionId']) {
        this.isSession = true;
      } else {
        this.isSession = false;
      }
    });

    if (localStorage.getItem('sessionId') == "5f5b20d1b9124f76ab3f56d4" && this.isSession == false) {
      $('#openLogin').trigger('click');
      //this.openLoginModel.nativeElement.click();
    } else {
      if (localStorage.getItem('sessionId') == "" || localStorage.getItem('sessionId') == "5f5b20d1b9124f76ab3f56d4" && this.isSession) {
        localStorage.setItem('sessionId', this.sessionId);
      }else{
        this.sessionId = localStorage.getItem('sessionId');
      }
      this.profileDetails();
      this.getVouchers();
    }

  }

  profileDetails() {
    this.apiService.getData('signupnexg/profile')
      .subscribe((data: any) => {
        if (Object.keys(data.result).length > 0) {
          this.registeredBy = data.result.register_by ==undefined ? '' : data.result.register_by;
          this.registredEmail = data.result.emailaddress ==undefined ? '' : data.result.emailaddress;
          this.registredMobileNumber = data.result.mobile ==undefined ? '' : data.result.mobile;
          this.name = data.result.first_name ==undefined ? '' : data.result.first_name;
          this.id = data.result._id.$oid;
       
          let UserData = {
            catlogue: 'Prideplex',
            user_id: this.id,
            page_name: 'Vouchers',
            mobile: this.registredMobileNumber,
            email: this.registredEmail,
            user_name: this.name,
            registeredby: this.registeredBy
          }
          let urlEncodedParams = this.userService.convertoJSONToUrlEncoded(UserData);
          this.assetsService.postVoucherUserDetail(urlEncodedParams).subscribe(data=>{
            
          });

          this.googleEvent.eventEmitter('Vouchers Event', 'Vouchers', 'Click on Vouchers page', 0, this.registredEmail==''? this.registredMobileNumber : this.registredEmail, 'Vouchers page');

        }

      },
        error => {
          this.some_error = 'Something went wrong!! Please try again after some time.';
        },
        () => {
        }
      );
  }


  openLoginModal(content) {

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false }).result.then((result) => {
    }, (reason) => {
    });

  }


  getVouchers() {
    let temp = {
      catlogue: CatlougeTypeConstants.catlogue,
      action: 'list'
    }

    let urlEncodedContestantParams = this.userService.convertoJSONToUrlEncoded(temp);
    this.assetsService.getVouchers(urlEncodedContestantParams).subscribe(data => {
      this.vouchersList = data.result;
    });

  }

  contentCopied(e) {
    console.log(e);
    var x = document.getElementById("snackbar");
    x.className = "show";
    setTimeout(function () { x.className = x.className.replace("show", ""); }, 1000);
  }

  landingPage(id) {
    // if (navigator.userAgent.match(/iPhone/i) ||
    // navigator.userAgent.match(/iPad/i) ||
    // navigator.userAgent.match(/Android/i)) {
      this.router.navigate(['vouchers-detail/' + id + '/' + this.sessionId])
  // }else{
  //   this.router.navigate(['vouchers-detail/' + id])
  // }
  }

  getCode(voucherId){
  this.CurrentVoucherId = voucherId;
  this.vCode ='';
  let temp = {
    user: this.registredEmail==''? this.registredMobileNumber : this.registredEmail,
    id: this.CurrentVoucherId
  }
  let urlEncodedContestantParams = this.userService.convertoJSONToUrlEncoded(temp);
  this.assetsService.getCode(urlEncodedContestantParams).subscribe(data => {
    this.getCodeData = data;
    this.vCode = data.code;
  });

  }


}
