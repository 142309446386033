import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { User } from '../models';
import { map } from 'rxjs/operators';


@Injectable()
export class VideoPlayerService {

  constructor(
    private apiService: ApiService,
    private jwtService: JwtService
  ) { }


  playUrl(credentials, is_trailer=0, is_live_evnet=0){
    if(is_trailer) {
      return this.apiService.playUrl(`getnewtrailerurlv3?${credentials}`).pipe(map(data => {
          return data;
          }
      ));
    // }
    // else if(is_live_evnet) {
    //   return this.apiService.playUrl(`getliveurlv3?${credentials}`).pipe(map(data => {
    //     return data;
    //     }
    // ));
    }else{
      return this.apiService.playUrl(`geturlv4?${credentials}`).pipe(map(data => {
        return data;
        }
    ));
    }
  }


  playAudioUrl(credentials){
   
    return this.apiService.playUrl(`assetdetail?${credentials}`).pipe(map(data => {
      return data;
      }
    ));
  }

  recommandationAudioTracks(credentials){
   
    return this.apiService.playUrl(`audio-recommandation?${credentials}`).pipe(map(data => {
      return data;
      }
    ));
  }

}
