import { Component, OnInit } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from "@angular/router";
declare var videojs;
declare var $: any;
@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.css']
})
export class AlertModalComponent implements OnInit {
  deviceMsg:any;
  modalRef:NgbModalRef;
  code:any = '';
  constructor(public router: Router) { }

  ngOnInit(): void {
  }

  onClose() {
    console.log(44);
    
    this.modalRef.close();
  }

  subscribe() {
    this.modalRef.close();
    var oldPl = document.getElementById('pl_one');
   
    // remove player
    // if (oldPl) {
    //   document.querySelector("ngb-modal-window").remove();
    //  console.log('remove old player');
     
    //   videojs(oldPl).dispose();
    // }
  
     this.router.navigate(["subscribe/chooseplan"], {
              queryParams: { code: this.code },
            });
            this.modalRef.dismiss();
            $('body').css('overflow', 'scroll');
          $('._play_modal_jw').css("visibility", "hidden");
          // $('._play_modal_jw').css("display", "none !important");
          $('._play_modal_jw').attr('style', 'display: none !important');
  }

}
