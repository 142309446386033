<div class="modal-header">
   <!-- <button type="button" id="closeAddToWatchListInBanner" class="close"
   (click)="onClose()">&times;</button> -->
</div>
<div class="modal-body">
   <div class="success-add">
      <img src="../../../assets/img/error_icon.png">
      <p>{{deviceMsg}}</p>
   </div>
</div>
<div class="btn-center-primary">
   <button type="button" class="btn" (click)="subscribe()">OK</button>
</div>