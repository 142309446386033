import { Injectable } from '@angular/core';

@Injectable()
export class JwtService {

  getToken(): String {
    return '646a27793a1bac3d4a20f56e';
  }

  saveToken(token: String) {
    window.localStorage['jwtToken'] = token;
  }

  destroyToken() {
    window.localStorage.removeItem('jwtToken');
  }

}
