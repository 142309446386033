// var filterDataType = localStorage.getItem('filterTypeData');
// var isCloudfrontEnabled  = JSON.parse(filterDataType).is_cloudfront_enabled;
// var bucket  = JSON.parse(filterDataType).bucket;

export const environment = {
  production: true,

  api_url: 'https://apps.digivive.com/prideplex/',
  play_url:'https://apps.digivive.com',
  api_base_url:'https://apps.digivive.com/production/',
  payment_base_url: 'https://payments.digivive.com/',
  razorpay_api_key: 'rzp_live_mvieFLYhrYNAAa',
  googleTrackingCode: 'G-X3XWSBQSCC',
  BASE_ASSETS_PATH: 'https://res.cloudinary.com/digivive/image/upload/',
  APP_CLOUD_NAME: 'v1603711950',
  SRC_EXT :'.webp' ,
  platformType: '1',
};