<div class="auth" id="login">

  <div class="modal-dialog" *ngIf="!signUpPageStatus">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title" *ngIf="loginPage">Login </h4>
        <h4 class="modal-title" *ngIf="forgotPasswordComponent">Forgot Password </h4>
        <h4 class="modal-title" *ngIf="otpInputbox">Enter OTP </h4>
        <h4 class="modal-title" *ngIf="resetPassword">Reset Password</h4>
        <h4 class="modal-title" *ngIf="tellUsScreen">Tell Us About You</h4>
        <!-- <h5 >{{forgotPasswordComponent?'An OTP will be send to your Register Phone No':''}}</h5> -->

        <button type="button" class="close" (click)="closeModal()" #closebutton>&times;</button>
      </div>

      <!-- Modal body Login Page -->
      <div class="modal-body" *ngIf="loginPage">
        <form [formGroup]="loginForm" (ngSubmit)="login()">

          <div class="form-group">
            <input type="text" class="form-control" formControlName="emailaddress" autocomplete="off" id="email"
              placeholder="Enter your registered mobile number/email ID"
              [ngClass]="{ 'is-invalid':isLoginSubmitted && loginFormControls.emailaddress.errors}">
            <div *ngIf="loginFormControls.emailaddress.errors" class="invalid-feedback _require_filed">
              <span
                *ngIf="loginFormControls.emailaddress.hasError('required') && (loginFormControls['emailaddress'].dirty || loginFormControls['emailaddress'].touched || loginFormControls['emailaddress'].errors.required)">Please
                enter a mobile number/ email ID</span>
              <span
                *ngIf="loginFormControls.emailaddress.hasError('pattern') && (loginFormControls['emailaddress'].dirty || loginFormControls['emailaddress'].touched)">Please
                enter a valid mobile number/email ID</span>

            </div>
          </div>

          <div class="form-group input-group last-margin">
            <input class="form-control" id="password" [type]="loginPassHide ? 'password' : 'text'" autocomplete="new-password"
              formControlName="pass" placeholder="Password" (keypress)="commonService.avoidSpace($event)"
              [ngClass]="{ 'is-invalid': isLoginSubmitted && loginFormControls.pass.errors }">

            <div class="input-group-prepend" (click)="loginPassHide = loginPassHide?false :true">
              <span class="input-group-text"><i class="fa fa-eye"></i></span>
            </div>

            <div *ngIf="isLoginSubmitted && loginFormControls.pass.errors" class="invalid-feedback _require_filed">
              <span *ngIf="loginFormControls.pass.errors.required">Please enter your password</span>
              <span *ngIf="loginFormControls.pass.errors.minlength">Password must be at least 6 characters</span>
            </div>

          </div>
          <div class="remeber_me">
            <div class="checkbox">
              <label><input type="checkbox" value=""><span class="checkmark"></span>Remember me</label>
            </div>
            <p class="forgot"><a (click)="forgotPasswordPage()">Forgot Password?</a></p>

            <div class="invalid-feedback _oth_requiered"
              style="display: block !important;text-align: center !important;margin-bottom: 0px;">
              <span>{{responseErr}}</span>
            </div>
          </div>


          <button type="submit" class="btn btn-default register">Submit</button>
        </form>
        <input type="hidden" id="item-input" #newItem value="true">
        <button type="button" [hidden]="true" (click)="addNewItem(newItem.value)" #forPopupModal>Check</button>
        <div class="loginlink">
          <p>Don't have an account? <a (click)="setLoginPage()">Register</a></p>
        </div>
        <button type="button" [hidden]="true" class="dropdown-item" (click)="openTellUs(content)"
          #openTellUsModel>Login</button>
      </div>


      <!-- Modal body forgot Page -->
      <div class="modal-body" *ngIf="forgotPasswordComponent">

        <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">


          <div class="form-group resetone">
            <p class="otp-center">An OTP will be sent to your registered Username</p>
            <input type="text" class="form-control" formControlName="emailaddress" (keypress)="clearResponseError()"
              autocomplete="off" id="email" placeholder="Enter your registered mobile number/email ID"
              [ngClass]="{ 'is-invalid': isForgotSubmitted && forgotFormControls.emailaddress.errors }">
            <div *ngIf="isForgotSubmitted && forgotFormControls.emailaddress.errors"
              class="invalid-feedback _require_filed forget_no">

              <span
                *ngIf="forgotFormControls.emailaddress.hasError('required') && (forgotFormControls['emailaddress'].dirty || forgotFormControls['emailaddress'].touched)">Please
                enter your registered mobile number/email ID</span>
              <span
                *ngIf="forgotFormControls.emailaddress.hasError('pattern') && (forgotFormControls['emailaddress'].dirty || forgotFormControls['emailaddress'].touched)">Please
                enter your registered mobile number/email ID</span>
              <!-- <span *ngIf="forgotFormControls.emailaddress.errors.required">Email address is required</span>
                  <span *ngIf="forgotFormControls.emailaddress.errors.minlength">Email address is required</span>
                  <span *ngIf="forgotFormControls.emailaddress.errors.pattern">Please enter the correct Email</span> -->
            </div>

          </div>

          <div class="invalid-feedback _oth_requiered" style="display: block !important; text-align:center !important;">
            <!-- <span>
                {{responseErr}}
              </span> -->

            <span
              *ngIf="responseErr != 'Your account is blocked for OTP service. Please go back and try again after 1 hr or Contact for support.'">{{responseErr}}</span>
            <span
              *ngIf="responseErr == 'Your account is blocked for OTP service. Please go back and try again after 1 hr or Contact for support.'">Your
              account is blocked for OTP service. Please go back and try again after 1 hr or <a
                href='/contactus'>Contact</a> for support.</span>

          </div>
          <button type="submit" class="btn btn-default register">SEND OTP</button>

        </form>


      </div>

      <!-- Modal body OTP Page -->
      <div class="modal-body" *ngIf="otpInputbox">

        <div class="_otp_sent_p">
          <p class="otp-center" *ngIf="!forgotPasswordForm.value.emailaddress">Please enter the OTP we have sent via SMS
            to your registered mobile number {{mobileNumber}}</p>
          <p class="otp-email" *ngIf="forgotPasswordForm.value.emailaddress">Please enter the OTP we have sent to your
            registered email ID {{emailId}}</p>
          <!-- <p class="otp-center" >1234 {{forgotPasswordForm.value.emailaddress?vaildEmail:vaildMobile}}</p> -->
        </div>

        <form [formGroup]="otpForm" (ngSubmit)="submitOtp()" class="resettwo">

          <div class="form-group">
            <input type="text" (keyup)="clearResponseError()" class="form-control" pattern="\d*" maxlength="6"
              formControlName="otp" id="otp" autocomplete="off" placeholder="Enter OTP"
              [ngClass]="{ 'is-invalid': isOtpSubmitted && otpFormControls.otp.errors }">
            <div *ngIf="isOtpSubmitted && otpFormControls.otp.errors " class="invalid-feedback _require_filed">
              <span *ngIf="otpFormControls.otp.errors.minlength && responseErr != ''">Please enter 6 digit OTP</span>
              <span *ngIf="otpFormControls.otp.errors.maxlength && responseErr != ''">Please enter 6 digit OTP</span>
              <span *ngIf="otpFormControls.otp.errors.length  && responseErr != ''">Please enter 6 digit OTP</span>
            </div>
          </div>
          <div>
          </div>
          <p class="forgot" *ngIf="error_code != '429'">
            <a (click)="requestForOtp()" class="resend" *ngIf="otpInt <= 0">Resend OTP</a>
            <a class="resend" *ngIf="otpInt > 0" style="color: #ccc;opacity: 0.5;">Resend OTP</a>
          </p>

          <div class="_custom_require_f">

            <div *ngIf="isOtpSubmitted && otpFormControls.otp.errors " class="invalid-feedback _require_filed"
              style="display: block !important;">
              <span *ngIf="otpFormControls.otp.errors.required && !resendOTPstatus" class="_long_otp_login_1">Please
                enter the OTP</span>
            </div>

            <div class="invalid-feedback _oth_requiered"
              style="display: block !important;text-align:center !important;">
              <span>
                <!-- {{responseErr}} -->
              </span>

              <span *ngIf="resendOTPstatus">
                {{forgotPasswordForm.value.emailaddress?'OTP has been sent to registered Email ID':'OTP has been sent to registered mobile number'}}
              </span>

            </div>


            <div class="invalid-feedback _oth_requiered _time_otp email-long"
              style="display: block !important; text-align:center !important;">
              {{responseErr}}
              <!-- <span *ngIf="otpInt > 0 && forgotPasswordForm.value.mobile && !responseErr && maxOtpAttempt > 0">
                OTP successfully sent to {{forgotPasswordForm.value.mobile}}
              </span>
              <span  class="email-long-span" *ngIf="otpInt > 0 && forgotPasswordForm.value.emailaddress && !responseErr && maxOtpAttempt > 0">
                OTP successfully sent to {{forgotPasswordForm.value.emailaddress}} 
              </span> -->
            </div>


            <!-- <div class="invalid-feedback _oth_requiered _time_otp email-long" 
              style="display: block !important; text-align:center !important;" *ngIf="maxOtpAttempt == 0"> 
              <span class="email-long-span" *ngIf="forgotPasswordForm.value.mobile">
                OTP successfully sent to {{forgotPasswordForm.value.mobile}} if OTP not received, 
                please go back and try again or
                contact customercare@digivive.com for support 
              </span>
              <span class="email-long-span" *ngIf="forgotPasswordForm.value.emailaddress">
                OTP successfully sent to {{forgotPasswordForm.value.emailaddress}} if OTP not received, 
                please go back and try again or
                contact customercare@digivive.com for support 
              </span>
           
            </div>  -->
          </div>


          <button type="submit" class="btn btn-default auth-btn otp-btn confirm-btn">Confirm</button>
        </form>


      </div>

      <!-- Modal body Reset Password Page -->
      <div class="modal-body" *ngIf="resetPassword">

        <form [formGroup]="resetPasswordForm" (ngSubmit)="submitResetPassword()">

          <div class="form-group input-group bot-error">
            <input type="text" class="form-control" formControlName="pass" id="pass"
              [type]="rPassdHide ? 'password' : 'text'" autocomplete="off" (keypress)="commonService.avoidSpace($event)"
              placeholder="Enter New Password"
              [ngClass]="{ 'is-invalid': isResetSubmitted && resetFormControls.pass.errors }">


            <div class="input-group-prepend" (click)="rPassdHide = rPassdHide ? false : true">
              <span class="input-group-text"><i class="fa fa-eye"></i></span>
            </div>
            <span class="right-msg">Minimum 8 Characters</span>

            <div *ngIf="isResetSubmitted && resetFormControls.pass.errors" class="invalid-feedback _require_filed">
              <span *ngIf="resetFormControls.pass.errors.required">Please enter a new password</span>
              <span class="_long_otp " *ngIf="resetFormControls.pass.errors.minlength">Password should be at least 8
                characters</span>
            </div>
          </div>
          <div class="form-group input-group">
            <input type="text" class="form-control" formControlName="cpass" id="cpass" autocomplete="off"
              (keypress)="commonService.avoidSpace($event)" [type]="rConfirmPassHide ? 'password' : 'text'"
              placeholder="Confirm New Password"
              [ngClass]="{ 'is-invalid': isResetSubmitted && resetFormControls.cpass.errors }">

            <div class="input-group-prepend" (click)="rConfirmPassHide = rConfirmPassHide ? false : true">
              <span class="input-group-text"><i class="fa fa-eye"></i></span>
            </div>

            <div *ngIf="isResetSubmitted && resetFormControls.cpass.errors" class="invalid-feedback _require_filed">
              <span *ngIf="resetFormControls.cpass.errors.required">Please re-enter new password</span>
              <span *ngIf="resetFormControls.cpass.errors.mustMatch">Confirm password does not match</span>
            </div>


            <div *ngIf="isResetSubmitted && resetFormControls.cpass.errors" class="invalid-feedback _require_filed">
              <span *ngIf="resetFormControls.cpass.errors.required">Please re-enter new password</span>
              <span *ngIf="resetFormControls.cpass.errors.mustMatch">Confirm password does not match</span>
            </div>
          </div>
          <div class="invalid-feedback" style="display: block !important; text-align:center !important;">
            <span>{{responseErr}}</span>
          </div>


          <button type="submit" class="btn btn-default confirm-btn auth-btn">Reset</button>
        </form>


      </div>

      <div class="modal-body success-change" *ngIf="resetPasswordSuccess">

        <div class="otp-success">
          <div class="success-msg">
            <h4>SUCCESS</h4>
            <p>Your password has been successfully changed!</p>
            <button (click)="resetLoginPage()" class="btn btn-default auth-btn">Continue to login</button>
          </div>
        </div>
      </div>

      <div class="modal-body" *ngIf="tellUsScreen">

        <form [formGroup]="tellUsForm" (ngSubmit)="postTellUs()" class="resettwo tellus_view">
          <!-- {{tellUsAllFieldsArray|json}} -->
          <div *ngFor="let tell of tellUsAllFieldsArray">
            <div *ngIf="tell.type=='input'" class="form-group">
              <div class="row">
                <!-- <div class="col-md-4">
                  <label class="tellus_view_label">{{tell.title}}</label>
                </div> -->

                <div class="col-md-12">

                  <div class="scroll_height">
                    <input type="text" class="form-control" [formControlName]="tell.slug" placeholder="{{tell.title}}">
                  </div>
                  <div *ngIf='isTellUsSubmitted && tellFormControls[tell.slug].errors' class="_require_filed">
                    <span
                      *ngIf='tellFormControls[tell.slug].hasError("required") && (tellFormControls[tell.slug].dirty || tellFormControls[tell.slug].touched || tellFormControls[tell.slug].errors.required)'>
                      {{tell.error_message}}
                    </span>
                  </div>

                </div>
              </div>
            </div>
            <div *ngIf="tell.type=='input_calender'" class="form-group">
              <div class="row">
                <div class="col-md-4">
                  <label class="tellus_view_label">{{tell.title}}</label>
                </div>

                <div class="col-md-8" style="padding: 0;">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="scroll_height">
                        <select class="form-control" name="day" id="day" formControlName="day">
                          <option>Day</option>
                          <option *ngFor="let item of [].constructor(31); let i = index" value="{{i+1}}">{{i+1}}
                          </option>
                        </select>
                      </div>

                      <div class="_require_filed">
                        <span
                          *ngIf="isTellUsSubmitted  && tellFormControls.day.errors && (tellFormControls.day.dirty || tellFormControls.day.touched || tellFormControls.day.errors.pattern)">Select
                          Day
                        </span>
                      </div>

                    </div>
                    <div class="col-md-4">
                      <select class="form-control" name="month" id="month" formControlName="month">
                        <option>Month</option>
                        <option *ngFor="let item of [].constructor(12); let i = index" value="{{i+1}}">{{i+1}}</option>
                      </select>
                      <div class="_require_filed">
                        <span
                          *ngIf=" isTellUsSubmitted && tellFormControls.month.errors && (tellFormControls.month.dirty || tellFormControls.month.touched || tellFormControls.month.errors.pattern)">Select
                          Month
                        </span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <select class="form-control" name="year" id="year" formControlName="year">
                        <option>Year</option>
                        <option *ngFor="let item of [].constructor((d-1950)+1); let i = index" value="{{i+1950}}">
                          {{i+1950}}
                        </option>
                      </select>
                      <div class="_require_filed">
                        <span
                          *ngIf="isTellUsSubmitted  && tellFormControls.year.errors &&  (tellFormControls.year.dirty || tellFormControls.year.touched || tellFormControls.year.errors.pattern)">Select
                          Year
                        </span>
                      </div>
                    </div>
                    <!-- <div class="col-md-12">
                    <div *ngIf='isTellUsSubmitted && (tellFormControls.day.errors && tellFormControls.month.errors && tellFormControls.year.errors )' class="_require_filed">
                      <span 
                      *ngIf='tellFormControls.day.hasError("required") || tellFormControls.month.hasError("required") || tellFormControls.year.hasError("required") && (tellFormControls.day.errors.required || tellFormControls.month.errors.required || tellFormControls.year.errors.required)'>
                     {{tell.error_message}}
                    </span> 
                    </div>
                  </div>  -->

                    <!-- <div *ngIf='isTellUsSubmitted && (tellFormControls.day.errors && tellFormControls.month.errors && tellFormControls.year.errors )' class="_require_filed">
                      {{tell.error_message}}
                      <span 
                        *ngIf='tellFormControls.name.hasError("required") && (tellFormControls[tell.slug].dirty || tellFormControls[tell.slug].touched)+"'>
                        Please enter a {{tell.slug}}
                      </span>
                    </div>  -->

                  </div>
                </div>
              </div>
            </div>
            <div class="form-group" *ngIf="tell.type=='radio'">
              <div class="row">
                <div class="col-md-4">
                  <label class="tellus_view_label">Gender </label>
                </div>
                <div class="col-md-8" style="padding: 0;">
                  <div class="row">
                    <!--*ngFor="let rad of tell.attributes"-->
                    <div *ngFor="let rad of tell.attributes" class="col-md-4" style="padding-right: 0;">
                      <label class="gander_radio">
                        <input type="radio" name="{{tell.slug}}" formControlName="{{tell.slug}}"
                          value="{{rad}}">{{rad}}</label>
                    </div>
                    <div class="col-md-12">
                      <div *ngIf='isTellUsSubmitted && tellFormControls[tell.slug].errors ' class="_require_filed">
                        <span
                          *ngIf='tellFormControls[tell.slug].hasError("required") && (tellFormControls[tell.slug].dirty || tellFormControls[tell.slug].touched || tellFormControls[tell.slug].errors.required)'>
                          {{tell.error_message}}
                        </span>

                      </div>
                    </div>
                    <!-- <div *ngIf='isTellUsSubmitted && tellFormControls.dob.errors' class="_require_filed">  
                      <span 
                        *ngIf="tellFormControls.name.hasError('required') && (tellFormControls['tell.slug'].dirty || tellFormControls['tell.slug'].touched)">
                        {{tell.error_message}}
                      </span>
                    </div>  -->

                  </div>
                </div>
              </div>
            </div>

          </div>

          <button type="submit" class="btn btn-default register _otp_reg">Done</button>
        </form>
      </div>
    </div>
  </div>
</div>


<div *ngIf="signUpPageStatus">
  <app-register></app-register>
</div>

<ng-template #content let-modal>
  <app-tell-us></app-tell-us>
</ng-template>

<button type="button" style="color: blueviolet;" [hidden]="true" class="dropdown-item"
      (click)="playAudio(audioContent)" #playAudioModal>Play Audio Modal</button>

<ng-template #audioContent let-modal>
  <app-audio-player [streamInfo]="track_detail" (onTrackActivity)="onTrackActivity($event)" (onModalClose)="onModalClosed($event)"></app-audio-player>
</ng-template>