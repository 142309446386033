import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { JwtService } from './jwt.service';
import { map } from 'rxjs/operators';
import { CatlougeTypeConstants, tempLiveData } from "src/app/core/constants";
import * as _ from 'lodash';
import { Subject } from 'rxjs';

@Injectable()
export class AssetsService {

  // Observable string sources
  private playTrackSource = new Subject<any>();
  private trackActivitySource = new Subject<any>();
  private podTrackActivitySource = new Subject<any>();


  // Observable string streams
  playTrack$ = this.playTrackSource.asObservable();
  trackActivity$ = this.trackActivitySource.asObservable();

  podTrackActivity$ = this.podTrackActivitySource.asObservable();


  // Service message commands
  playTrack(track: any) {
    this.playTrackSource.next(track);
  }

  // announcement in album
  trackActivity(track: any) {
    console.log('trackActivity: ', track);

    this.trackActivitySource.next(track);
  }

  // announcement in album
  podTrackActivity(track: any) {
    console.log('annoucing');

    this.podTrackActivitySource.next(track);
  }


  userInfo: any = localStorage.getItem('userInfo');
  sessionId: any;
  catlouge: string;
  viewAllData: any = [];
  homePageAssetsData: any = [];
  constructor(
    private apiService: ApiService,
    private jwtService: JwtService
  ) {

  }

  getHomePageTabData(params) {

    return this.apiService.get(`tabdata?${params}`).pipe(map(data => {
      this.homePageAssetsData = data;
      return data;
    }));

  }

  addRemoveFavourite(data) {
    return this.apiService.post('favourite?call=addfavourite', data).pipe(map(data => {
      return data;
    }));
  }

  reorderFavourite(data, params) {
    return this.apiService.post(`favourite?${params}`, data).pipe(map(data => {
      return data;
    }));
  }
  getFavGuruJiList(params) {
    return this.apiService.get(`favourite?${params}`).pipe(map(data => {
      return data;
    }));
  }


  getAllGuruJiList(params) {
    return this.apiService.get(`favourite?${params}`).pipe(map(data => {
      return data;
    }));
  }



  getContinueWatchingData(params) {

    return this.apiService.get(`tabdata?${params}`).pipe(map(data => {
      this.homePageAssetsData = data;
      return data;
    }));

  }


  getHomePageAssets(params) {

    if (this.jwtService.getToken()) {
      if (typeof this.userInfo != 'object') {
        this.userInfo = JSON.parse(this.userInfo);
      }
      // this.userInfo = JSON.parse(this.userInfo)
      this.catlouge = localStorage.getItem('sessionId');
      this.catlouge = CatlougeTypeConstants.catlogue;
      if (this.userInfo) {
        this.sessionId = this.userInfo.result.sessionId;
      }
      return this.apiService.get('homecategoryasset?catlogue=' + this.catlouge).pipe(map(data => {
        this.homePageAssetsData = data;
        return data;
      }));

    }

  }

  get getHomePageAssetsData() {
    return this.homePageAssetsData;
  }


  // deleteContinueWatching(recentWatchedDelete) {


  //   return this.apiService.post('videoend',  recentWatchedDelete).pipe(map(data => {
  //     return data;
  //   }));

  // }

  deleteContinueWatching(recentWatchedDelete) {

    return this.apiService.get(`tabdata?${recentWatchedDelete}`).pipe(map(data => {
      return data;
    }));

  }


  detailEpisode(params) {
    return this.apiService.get(`assetdetailpage?${params}`).pipe(map(data => {
      return data;
    }));

  }


  getRecommended(params) {
    return this.apiService.get(`recommandation?${params}`).pipe(map(data => {
      return data;
    }));

  }

  recommandationAlbumPlaylist(credentials) {

    return this.apiService.playUrl(`audio-recommandation?${credentials}`).pipe(map(data => {
      return data;
    }
    ));
  }

  // getAssetDetail(params) {
  //   return this.apiService.get(`assetdetailpage?${params}`).pipe(map(data => {
  //     return data;
  //   }));
  // }

  getAssetDetail(params) {
    return this.apiService.get(`assetdetail?${params}`).pipe(map(data => {
      return data;
    }));
  }

  getViewAll(params) {

    return this.apiService.get(`tabdata?${params}`).pipe(map(data => {
      return data;
    }));

  }


  // getViewAll(params, pageType, gener?: any) {

  //   let viewAllPath = ''
  //   if (pageType == 'movie') {

  //     viewAllPath = 'genrewithassets'

  //   } else if (pageType == 'vod') {

  //     viewAllPath = 'livetv'

  //   } else if (pageType == 'tvshow') {

  //     viewAllPath = 'genrewithassets'

  //   } else if (pageType == 'livetv') {

  //     viewAllPath = 'genrewithassets'

  //   } else {
  //     viewAllPath = 'homecategoryasset'
  //   }
  //   let dat: any = [];
  //   return this.apiService.get(`${viewAllPath}?${params}`).pipe(map(data => {

  //     if (gener)
  //       dat[gener] = data;

  //     let index =  this.viewAllData.findIndex(d =>d[gener] != undefined )

  //     if (index > -1) {
  //       dat.splice(index, 0, dat)
  //     } else {
  //       this.viewAllData.push(dat);
  //     }

  //     return data;
  //   }));

  // }

  viewAllSavedData(gener) {
    return this.viewAllData.find(d => d[gener] != undefined)
  }


  getEpgData() {
    // return 'epgData'
    let programDateArr = [];
    let test = tempLiveData.egpData;
    let tempProgramByData = [];
    console.log(test);
    test.result.forEach(element => {
      programDateArr.push(element.progdate)
      // console.log(programDateArr)
    });
    console.log(_.uniq(programDateArr));
    let programDateUniqArr = _.uniq(programDateArr)
    programDateUniqArr.forEach((element, index) => {
      console.log(index, programDateUniqArr.length - 1);
      tempProgramByData.push(test.result.filter(program => element == program.progdate));
      if (index == programDateUniqArr.length - 1) {
        console.log(tempProgramByData)
      }
    });
  }

  getContestDetail(params) {
    return this.apiService.get(`tabdata?${params}`).pipe(map(data => {
      return data;
    }));
  }

  postContestDetail(credentials) {
    this.catlouge = CatlougeTypeConstants.catlogue;
    return this.apiService.post('tabdata?catlogue=' + this.catlouge + '&call=votingpoll', credentials).pipe(map(data => {
      return data;
    }
    ));

  }

  getPanchang(params) {
    return this.apiService.get(`getrssfeed?${params}`).pipe(map(data => {
      return data;
    }));
  }

  getVouchers(params) {
    return this.apiService.get(`voucher?${params}`).pipe(map(data => {
      return data;
    }));
  }

  getCode(params) {
    return this.apiService.post('voucher?action=getcode', params).pipe(map(data => {
      return data;
    }
    ));

  }

  postVoucherUserDetail(credentials) {
    return this.apiService.post('voucher?action=useractivity', credentials).pipe(map(data => {
      return data;
    }
    ));

  }

  getHoroscopeData(params) {
    return this.apiService.post('getrssfeed?action=gethoroscope', params).pipe(map(data => {
      return data;
    }
    ));

  }

  checkHoroscopeData(params) {
    return this.apiService.post('getrssfeed?action=gethoroscopestatus', params).pipe(map(data => {
      return data;
    }
    ));

  }
}

