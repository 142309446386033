import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isVouchersPage = false;
  isHoroscopePage = false;
  isMandirPage: boolean = false;
  isAppsPages: boolean = false;
  currentYear: number = new Date().getFullYear();

  constructor( private router: Router,) { }

  ngOnInit(): void {
    if ((this.router.url).includes('/vouchers')) {
      console.log("vouchers");
      if (navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/Android/i)) {
        this.isVouchersPage = true;
        console.log("yes");
      }
    }

    if ((this.router.url).includes('/horoscope')) {
      if (navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/Android/i)) {
        this.isHoroscopePage = true;
      }
    }

    if ((this.router.url).includes('/virtual-mandir')) {
      if (navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/Android/i)) {
        this.isMandirPage = true;
      }
    }

    if ((this.router.url).includes('/ios')  || (this.router.url).includes('/android') || (this.router.url).includes('/tv')) {
      if (navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/Android/i)) {
        this.isAppsPages = true;
      }
    }


  }

  flipLogoPlay(){
    console.log('Logo')
  }

  flipLogoApp(){

  }

}
