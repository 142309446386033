<div style="position: relative;">
<section class="slider_img" *ngIf="bannerResult">   
  
          <div class="inner_img">    
              <div class="owl-carousel banner_slider owl-theme" >
                <owl-carousel-o [options]="owlOption">
                    <ng-container *ngFor="let item of data; let i=index">
                        <ng-template carouselSlide   class="item">
                          <div class="_slider_c" (click)="item.type == 'audio' ? playVideo(item, audioContent,loginContent) :playVideo(item, content, loginContent)">
                            <!-- <img src="{{item.image}}"> -->
                            <!---For cloudinary-->
                            <div>
                            <image-manipulation [img]="isCloudFrontType==false ? item.image : item.image_cloudfront_url" [params]="{'width':836}" [notFound]="notFoundImg"></image-manipulation>
                            </div>
                            <!-- <div id="forCloudFront" *ngIf="isCloudFrontType">
                              <image-manipulation [img]="b.image_cloudfront_url" [params]="{'width':836,'height':500}" [cloudFrontData]="bannerResult.cludfrontdata"></image-manipulation>
                            </div> -->
                            <div class="_inner_s_c">
                                <h2>{{item.name}} </h2>
                                <!-- <ul *ngIf="item">
                                  <li *ngIf="item.genre_text">{{item.genre_text}}</li>
                                  <li *ngIf="item.languages.length>0">{{item.languages}}</li>    
                                </ul>  -->
                                <p>{{item.banner_synopsis | slice:0:300}} 
                                  <span *ngIf="item.banner_synopsis && item.banner_synopsis.length > 300">...</span>
                              </p>
                              <div *ngIf="item?.viewer_rating?.rating_name" class="rating_bottom_show">
                                
                                  {{item?.viewer_rating?.rating_name}}
                               
                              </div>
                              <div class="_s_play_btn" *ngIf="!item.data_type">
                                <a  class="active_btn"  (click)="item.type == 'audio' ? playVideo(item, audioContent,loginContent) :playVideo(item, content, loginContent)">{{item.type == 'audio' || item.type == 'playlist' || item.type == 'podcast' ? 'Play now' : 'Watch now'}}<img src="assets/img/Watch_Now.png"></a>
                                <a href="javascript:;"
                                class="add_btn" *ngIf="(item.type != 'event' && item.type != 'audio' && item.type != 'playlist' && item.type != 'podcast')"
                                (click)="AddToWatchListInBanner($event, item, loginContent)">Watchlist
                                <img [src]="item.is_watchlist_added?'assets/img/Added_to_Watchlist.png':'assets/img/Add-to_Watchlist.png'">                                
                              </a>
                              <span class="tooltip_hover" *ngIf="(item.type != 'event' && item.type != 'audio' && item.type != 'playlist' &&  item.type != 'podcast')">

                                {{item.is_watchlist_added?'Remove from Watchlist':'Add to Watchlist'}}   


                              </span>
                              </div>
                            </div>
                        </div>
                
                        </ng-template> 
                    </ng-container>
                </owl-carousel-o>           
              </div>
          </div>       
      
</section>
<!-- <div class="container" style="position: relative;">
  <a class="vm-icon" href="javascript:void(0)" (click)="openVritualMandirModal(VritualMandirContent,loginContent)"><img src="../../../assets/img/vm/virtual_mandir_icon.png" /></a>
</div> -->
</div>


<a data-toggle="modal" data-target="#addToWatchListfrombanner" #addToWatchListSuccessFromBanner></a>
<div class="modal auth" id="addToWatchListfrombanner" *ngIf="userInfo">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
      <div class="modal-content">
  
        <!-- Modal Header -->
        <div class="modal-header" style="visibility: hidden;">
          <button type="button" id="closeAddToWatchListInBanner" class="close" data-dismiss="modal">&times;</button>
        </div>
  
        <!-- Modal body -->
        <div class="modal-body">
          <div class="success-add">
            <img src="../../../assets/img/success.png">
            <p *ngIf="error_code == '200'">Successfully added to Watchlist.</p>
            <p *ngIf="error_code == '209'">Successfully removed from Watchlist.</p>
            <!-- <p id="bannerAddToWatchList">{{error_string}}</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>


       <!--Cancel subscription -->
      <div class="modal auth" id="add_watch_list_from_banner" *ngIf="userInfo">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Are you sure want to add?</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeAddtoWatchListModal>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <!-- <div class="modal-body" *ngIf="remove_subscription_error != ''">
                <p class="error">{{remove_subscription_error}}</p>
              </div> -->
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="addWatchList(loginContent)">Yes</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
            <a  data-toggle="modal" data-target="#pack_removed_success"></a>
            </div>
          </div>
        </div>
      </div>
      <!-- Cancel subscription -->
      

<ng-template #content let-modal class="_parent_jw_m">
    <app-jw-video-player [streamInfo]="streamInfo" class="_p_jw_player"></app-jw-video-player>
  </ng-template>

  
<ng-template #loginContent let-modal>
    <app-login></app-login>
</ng-template>


<ng-template #audioContent let-modal>
  <app-audio-player [streamInfo]="track_detail" (onTrackActivity)="onTrackActivity($event)" (onModalClose)="onModalClosed($event)"></app-audio-player>
</ng-template>



<ng-template #VritualMandirContent let-modal>
<app-virtual-mandir></app-virtual-mandir>
</ng-template>