import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable, ReplaySubject, throwError} from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class AccountService {
  END_POINT = environment.api_url;
  private _loadedLibraries: { [url: string]: ReplaySubject<any> } = {};
  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private readonly document: any,
  ) { }

  getData(apiMethod: string, param?: any): Observable<any> {
    return this.http.get(this.END_POINT + apiMethod, param).pipe(
      catchError(this.handleError)// then handle the error
    );
  }

  postData(apiMethod: string, param: any): Observable<any> {
    return this.http.post(this.END_POINT + apiMethod, param).pipe(
      catchError(this.handleError)// then handle the error
    );
  }

  updateData(id: number, param: any): Observable<any> {
    return this.http.put(this.END_POINT + id, param).pipe(
      catchError(this.handleError)
    );
  }

  lazyLoadLibrary(resourceURL:any): Observable<any> {
    return forkJoin([
        this.loadScript(resourceURL)
    ]);
  }

  private loadScript(url: string): Observable<any> {
    if (this._loadedLibraries[url]) {
        return this._loadedLibraries[url].asObservable();
    }
  
    this._loadedLibraries[url] = new ReplaySubject();
  
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.onload = () => {
        this._loadedLibraries[url].next(1);
        this._loadedLibraries[url].complete();
    };
  
    this.document.body.appendChild(script);    
    return this._loadedLibraries[url].asObservable();
  }  


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    console.log(HttpErrorResponse);
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  
}
