<div class="dark mobile-app">
    <div id="content">
        <div class="container-txt">
            <h3>FAQs</h3>
            
            <h6>Q1) What all can I watch on PridePlex ?</h6>
            <p>Prideplex is an OTT service for Assamese language audience where you can enjoy music and poems, watch celebrity interviews, music videos, movies and Assamese web series.</p>
            
            <h6>Q2) I am not able to stream any content, what should I do?</h6>
            <p>The problem might be because there is not enough network coverage in your region. It is suggested to set your streaming option to low quality if network signal is weak. If problem still persists, please mail us at <span style="color: #4aadfa;">support@prideplex.com</span></p>
            
            <h6>Q3) Does PridePlex provide search functionality?</h6>
            <p>Yes, PridePlex application provides an advanced search functionality for its users to easily find content they are looking for. You need to type the search keyword in the search box available. On typing the search keyword, you will be shown the relevant search results from where you can access the content.</p>
            
            <h6>Q4) Can I watch PridePlex on PC /Laptop as well?</h6>
            <p>PridePlex now offers multi-screen viewing of your favourite content with the same subscription. You can enjoy PridePlex service across all your personal screens - PC/Laptop/Mobile/Smart TV devices. For more details please go through our website <span style="color: #4aadfa;">www.PridePlex.com</span></p>
            
            <h6>Q5) Can I watch PridePlex globally?</h6>
            <p>Yes, PridePlex is available globally and can be watched outside of India as well. However, the content catalouge may vary as per respective territory.</p>
            
            <h6>Q6) How to contact PridePlex in case of any support?</h6>
            <p>For any query, please mail us at <span style="color: #4aadfa;">support@prideplex.com</span>.</p>
            
        </div>
    </div>
</div>