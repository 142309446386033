import { Component, OnInit } from '@angular/core';
import { noActivePlanOptions } from '../../lottie-config';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-no-active-plan',
  template: `
  <div class="d-flex justify-content-center align-items-center">
    <div style="width: 200px">
      <ng-lottie [options]="noActivePlanOptions"></ng-lottie>
    </div>
  </div>
  `,
  styles: [
  ]
})
export class NoActivePlanComponent implements OnInit {

  noActivePlanOptions: AnimationOptions = noActivePlanOptions;

  constructor() { }

  ngOnInit(): void {
  }

}
