import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { AssetsService } from 'src/app/core/services/assets.service';
import { TrackSettingsModalComponent } from 'src/app/shared/track-settings-modal/track-settings-modal.component';
import { CommonService } from 'src/app/core/services/common.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/core';
import { CatlougeTypeConstants } from 'src/app/core/constants';
import { ShareModalComponent } from 'src/app/shared/share-modal/share-modal.component';
import { jwPlayerService } from '../jw-video-player/jwplayer.servies';
import { Subscription } from 'rxjs';
import { log } from 'console';

declare var videojs;
declare var $: any;

@Component({
  selector: 'app-podcast',
  templateUrl: './podcast.component.html',
  styleUrls: ['./podcast.component.css']
})
export class PodcastComponent implements OnInit {

  catlouge = CatlougeTypeConstants.catlogue;
  isCloudFrontType: boolean = true;
  notFoundImg: any;
  is_preview: any;
  userInfo: any;
  copyLink: boolean = false;
  share_url_link: any;
  similar_albums: any;
  charge_code: any;
  podcast_details: any;
  page: number = 1;
  allEpisode: any;
  seasonData: any;
  seasonIndex: number = 0;
  currentSeaon: any;
  readMoreText: string = 'View More';
  readmoreCond: boolean = false;
  totalSeason: any;
  playEpisodeData: any;
  isPlan: any;
  similerDetails: any;

  progressInc: number = 0;
  progrssBarValue: number = 0;
  currentPlayCode: any;
  isScroll: boolean = false;
  is_pause: boolean = true;
  isAudioExist: boolean = true;
  @ViewChild("showErrorMsgModal") showErrorMsgModal;
  paidPackMsg: any;
  @ViewChild("closeShowErrorMsg") closeShowErrorMsg;
  playerSubscriber: any = '';
  episodeIndex: any;
  onlyPodcst: boolean = false;

  subscription: Subscription;
  isFirstEpisode: any = '';
  tempcode: any;
  tempType: any;
  currentPlaySeasonId: any;
  playformSeasonId: any;
  @ViewChild('loginContent') loginContent: ElementRef;


  constructor(public activatedRoute: ActivatedRoute,
    public assetsService: AssetsService,
    public userService: UserService,
    private modalService: NgbModal,
    public commonService: CommonService,
    private router: Router,
    public jwservice: jwPlayerService,) {

    // this.playerSubscriber = this.jwservice.closePlayerEvent.subscribe((isClosed) => {
    //   console.log('isClosed');
    //   if (isClosed) {
    //     this.getAssetsData();
    //     this.jwservice.closePlayer.next(false);
    //   }
    // });
    this.subscription = assetsService.podTrackActivity$.subscribe(
      activity_d => {
        console.log('activity data', activity_d);
        this.onlyPodcst = activity_d.is_podcst;
        this.currentPlayCode = activity_d.currentCode;
        this.is_pause = activity_d.is_pause;
        this.is_preview = activity_d.is_preview;
        this.isAudioExist = activity_d.isAudioExist;

      });

  }

  ngAfterViewInit() {
    if (!localStorage.getItem('isAuthenticated')) {
      try {
        this.modalService.open(this.loginContent, { ariaLabelledBy: 'modal-basic-title', windowClass: 'no_close', backdrop: 'static', keyboard: false }).result.then((result) => {
        }, (reason) => { });
      } catch (e) {
        console.log(e);
      }
    }
  }

  ngOnInit(): void {
    this.readmoreCond = false;
    this.readMoreText = 'View More'
    this.notFoundImg = 'square';

    if (this.commonService.filterType == 1) {
      this.isCloudFrontType = true;
    } else {
      this.isCloudFrontType = false;
    }
    this.activatedRoute.params.subscribe(params => {
      this.tempcode = params.code;
      this.tempType = params.type;
      this.getAssetsData();
    });
    this.liniarProgressBar();
  }

  ngOnDestroy() {
    // this.playerSubscriber.unsubscribe();
    var oldPl = document.getElementById('pl_one');
    if (this.is_preview) {
      if (oldPl) {
        //this.stopPreview();
      }
    }

  }


  getAssetsData() {
    let assetDetail = {
    }
    // this.activatedRoute.params.subscribe(params => {
    //console.log('parms', params);
    //this.charge_code = params.code;
    assetDetail = {
      "code": this.tempcode,
      "catlogue": this.catlouge,
      "platform": 'web',
      "type": this.tempType
    }
    // });

    let urlEncodedDetail = this.userService.convertoJSONToUrlEncoded(assetDetail);

    this.assetsService.getAssetDetail(urlEncodedDetail).subscribe(data => {
      if (data && data.result) {

        this.playformSeasonId = data.result.show_id;
        var oldPl: any = document.getElementById('pl_one');
        // check if audio player already playing
        if (oldPl) {
          let code = $('#AudioRepeatButton').attr('data-code');
          let playlistId = $('#AudioRepeatButton').attr('data-playlistId');
          let isPreviewOrNot = $('#AudioRepeatButton').attr('data-isPreview');

          if (code) {
            if (data.result._id == playlistId) {
              if (isPreviewOrNot == 'true') {
                this.is_preview = true;
                this.is_pause = true;
                this.currentPlayCode = code;
                this.isAudioExist = false;
              } else {
                this.is_preview = false;
                this.is_pause = false;
                this.currentPlayCode = code;
                this.isAudioExist = true;
              }
            }
          }
        }

        this.podcast_details = data.result;
        console.log(this.podcast_details);
        this.share_url_link = data.share_url;
        assetDetail['type'] = 'pcseasons';

        if (data.error_code === 401) {
          window.location.replace('404')
        }
        this.seasonData = data;

        this.totalSeason = data.result.season;

        if (this.seasonData.result.selected_season != "" && this.seasonData.result.selected_season != 0) {
          if (this.seasonData.result.recently_watched && this.seasonData.result.recently_watched.season) {
            assetDetail['season_id'] = this.seasonData.result.recently_watched.season_id;

            let urlEncodeSeadDetail = this.userService.convertoJSONToUrlEncoded(assetDetail);
            this.assetsService.getAssetDetail(urlEncodeSeadDetail).subscribe(episodeData => {
              this.allEpisode = episodeData;
            });

            this.changeSeasonIndex(this.seasonData.result.recently_watched.season.season_number, (this.seasonData.result.recently_watched.season.season_number));
          }
        } else {
          assetDetail['season_id'] = this.seasonData.result.season?.[0].season_id;

          let urlEncodeSeadDetail = this.userService.convertoJSONToUrlEncoded(assetDetail);
          this.assetsService.getAssetDetail(urlEncodeSeadDetail).subscribe(episodeData => {
            this.allEpisode = episodeData;
            this.isFirstEpisode = episodeData.result[0]?.assets[0];
          });
          this.changeSeasonIndex(this.seasonData.result.season?.[0].season_number, (this.seasonData.result.season?.[0].season_number));




        }

        // if(this.isFirstEpisode!=""){
        //   this.isFirstEpisode;
        // }else{
        //   this.isFirstEpisode = this.podcast_details.recently_watched;
        // }

        setTimeout(() => {
          document.getElementById("nexg_ngb-progressbar").style.display = 'none';
          document.getElementById('maincontentarea').classList.remove("_beforeLoad");
        }, 3000);
      }

    });

    assetDetail['type'] = 'podcast';
    let urlEncodedPodcatSimiler = this.userService.convertoJSONToUrlEncoded(assetDetail);
    this.assetsService.recommandationAlbumPlaylist(urlEncodedPodcatSimiler).subscribe(data => {
      if (data && data.result) {
        if (data.error_code === 401) {
          window.location.replace('404')
        }
        this.similerDetails = data.result;
      }

    });

  }


  liniarProgressBar() {
    // progress bar code
    this.progrssBarValue = 0;
    this.progressInc = 0;
    let progrssInterval = setInterval(() => {
      this.progrssBarValue = this.progressInc * 10;

      if (this.progressInc >= 10) {
        clearInterval(progrssInterval);
      }
      this.progressInc++;
      console.log(this.progrssBarValue);
    }, 500)

  }

  detailPageRedirect(recommedDetail) {
    this.is_pause = true;
    // this.copyLink = false;
    // document.getElementById("nexg_ngb-progressbar").style.display = 'block';
    // document.getElementById('maincontentarea').classList.add("_beforeLoad");

    // this.liniarProgressBar();
    // window.scrollTo(0, 0);


    recommedDetail.name = recommedDetail.name != "" && recommedDetail.name != undefined ? recommedDetail.name.toString().toLowerCase() : ""
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');

    this.commonService.goToDetail(recommedDetail, 'Screen_Detail_Similar');
    this.readmoreCond = false;
    this.readMoreText = 'View More'
  }

  changeSeason(seasonId, seasonNumber, seasonName) {
    console.log("changessssssssssssss");
    this.page = 1;
    let seaDetail = {
      "code": this.seasonData.result.code,
      "catlogue": this.catlouge,
      "platform": 'web',
      "season_id": seasonId,
      "type": 'pcseasons'
    }
    let urlEncodeSeadDetail = this.userService.convertoJSONToUrlEncoded(seaDetail);
    this.assetsService.getAssetDetail(urlEncodeSeadDetail).subscribe(episodeData => {
      this.allEpisode = episodeData;
    });
    this.changeSeasonIndex(seasonNumber, seasonName);
  }

  changeSeasonIndex(index, seasonName) {
    this.page = 1;
    this.seasonIndex = this.seasonData.result.season?.findIndex(sea => sea.season_number == seasonName)
    this.currentSeaon = 'Season ' + (index ? index : 1);
  }

  @HostListener("window:scroll", [])
  onScroll() {
    let maxPage = this.seasonData && this.seasonData.result && this.seasonData.result.season[this.seasonIndex] ? parseInt(this.seasonData.result.season[this.seasonIndex].total_pages) : 0;

    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight * .7 && this.isScroll == false) {
      if (maxPage >= this.page) {
        this.page++;
        this.isScroll = true;
        let seaDetail = {
          "code": this.seasonData.result.code,
          "catlogue": this.catlouge,
          "platform": 'web',
          "season_id": this.seasonData.result.season[this.seasonIndex].season_id,
          "page": this.page,
          "type": 'pcseasons'
        }
        let urlEncodeSeadDetail = this.userService.convertoJSONToUrlEncoded(seaDetail);
        try {
          setTimeout(() => {
            this.assetsService.getAssetDetail(urlEncodeSeadDetail).subscribe(episodeData => {
              this.isScroll = false;
              let getLastdata = episodeData.result[0]?.assets;
              let preData = this.allEpisode.result[0]?.assets;
              this.allEpisode.result[0].assets = [...preData, ...getLastdata];

            });
          }, 1000);
        } catch (err) {
          alert(JSON.stringify(err));
        }

      }
    }
  }

  readMore() {
    this.readMoreText = this.readMoreText == "View More" ? "Read Less" : "View More";
    this.readmoreCond = this.readmoreCond ? false : true;
    if (this.readmoreCond) {
      $(".podcast_detail p").css({ "width": "100%" });
    } else {
      $(".podcast_detail p").css({ "width": "50%" });
    }
  }

  expendEpisodeSynopsis(i) {
    this.episodeIndex = i
  }

  openShareModal(link) {
    const modalRef = this.modalService.open(ShareModalComponent, { ariaLabelledBy: 'modal-basic-title', windowClass: 'album_more_info_list auth', backdrop: true, keyboard: false });
    let component_instance: any = (<ShareModalComponent>modalRef.componentInstance);
    component_instance.share_url_link = link;
    component_instance.shared_data = this.podcast_details;
    component_instance.modalRef = modalRef;
  }


  playAudioRecomnd(data, play_episode, is_preview = false, isTopIcon = false) {
    localStorage.setItem('is_check_device', 'true');
    this.currentPlaySeasonId = data.show_id;
    var oldPl = document.getElementById('pl_one');
    if (oldPl && this.onlyPodcst) {
      if (is_preview) {
        this.is_preview = true;
        this.is_pause = true;
        this.isAudioExist = false;
        this.currentPlayCode = data.code;
        this.onlyPodcst = true;
        setTimeout(() => {
          $('body').css('overflow', 'scroll');
          $('._play_modal_jw').css("visibility", "hidden");
          $('._play_modal_jw').attr('style', 'display: none !important');
        }, 100);
      } else {
        if (oldPl && !this.is_preview && isTopIcon && this.currentPlayCode == data.code) {
          videojs(oldPl).play();
          this.is_preview = false;
          this.is_pause = false;
          this.isAudioExist = true;
          this.currentPlayCode = data.code;
          this.onlyPodcst = true;
          return;
        }

        if (oldPl && this.is_preview && isTopIcon) {
          videojs(oldPl).dispose();

          this.playEpisodeData = data;
          this.playEpisodeData['plan'] = this.isPlan;
          this.playEpisodeData['recently_watched'] = {};
          this.playEpisodeData['recently_watched']['season_id'] = data.season_id;
          this.playEpisodeData['recently_watched']['stop'] = data.stop != undefined ? data.stop : data.seek_bar_data.stop;
          this.playEpisodeData['recently_watched']['duration'] = data.duration != undefined ? data.duration : data.seek_bar_data.duration;
          if (((data.stop != undefined ? data.stop : data.seek_bar_data.stop * 100) / (data.duration != undefined ? data.duration : data.seek_bar_data.duration)) >= 98) {
            this.playEpisodeData['recently_watched']['stop'] = 0;
          }
          this.playEpisodeData['recently_watched']['asset_mongo_id'] = this.playEpisodeData.asset_mongo_id;
          this.playEpisodeData['recently_watched']['charge_code'] = this.playEpisodeData.charge_code;
          this.playEpisodeData['recently_watched']['code'] = this.playEpisodeData.code;
          this.playEpisodeData['recently_watched']['content_availability'] = this.playEpisodeData.content_availability;
          this.playEpisodeData['recently_watched']['thumbnail'] = this.playEpisodeData.thumbnail;
          this.playEpisodeData['recently_watched']['sec'] = this.playEpisodeData.sec;
          this.playEpisodeData['recently_watched']['min'] = this.playEpisodeData.min;
          this.playEpisodeData['recently_watched']['hours'] = this.playEpisodeData.hours;
          this.playEpisodeData['recently_watched']['genre_text'] = this.playEpisodeData.genre_text;
          this.playEpisodeData['recently_watched']['languages'] = this.playEpisodeData.languages;
          this.playEpisodeData['recently_watched']['synopsis'] = this.playEpisodeData.synopsis;
          this.playEpisodeData['totalSeason'] = Object.keys(this.totalSeason).length;
          this.playEpisodeData['is_preview'] = is_preview;
          this.playEpisodeData['podcast_id'] = this.podcast_details._id;

          this.playEpisodeData.type = 'pc_episode';
          this.is_preview = false;
          this.is_pause = false;
          this.isAudioExist = true;
          this.currentPlayCode = data.code;
          this.onlyPodcst = true;
          setTimeout(() => {
            $('body').css('overflow', 'scroll');
            $('._play_modal_jw').css("visibility", "visible");
            $('._play_modal_jw').attr('style', 'display: block !important');
          }, 100);
          this.modalService.open(play_episode, { ariaLabelledBy: 'modal-basic-title', windowClass: '_play_modal_jw audio_bar', backdrop: false, keyboard: false }).result.then((result) => {
          }, (reason) => {
          });
          return;
        }

        this.is_preview = false;
        this.is_pause = false;
        this.isAudioExist = true;
        this.currentPlayCode = data.code;
        this.onlyPodcst = true;

        setTimeout(() => {
          $('body').css('overflow', 'scroll');
          $('._play_modal_jw').css("visibility", "visible");
          $('._play_modal_jw').attr('style', 'display: block !important');
        }, 100);
      }

      this.playEpisodeData = data;
      this.playEpisodeData['plan'] = this.isPlan;
      this.playEpisodeData['recently_watched'] = {};
      this.playEpisodeData['recently_watched']['season_id'] = data.season_id;
      this.playEpisodeData['recently_watched']['stop'] = data.stop != undefined ? data.stop : data.seek_bar_data.stop;
      this.playEpisodeData['recently_watched']['duration'] = data.duration != undefined ? data.duration : data.seek_bar_data.duration;
      if (((data.stop != undefined ? data.stop : data.seek_bar_data.stop * 100) / (data.duration != undefined ? data.duration : data.seek_bar_data.duration)) >= 98) {
        this.playEpisodeData['recently_watched']['stop'] = 0;
      }
      this.playEpisodeData['recently_watched']['asset_mongo_id'] = this.playEpisodeData.asset_mongo_id;
      this.playEpisodeData['recently_watched']['charge_code'] = this.playEpisodeData.charge_code;
      this.playEpisodeData['recently_watched']['code'] = this.playEpisodeData.code;
      this.playEpisodeData['recently_watched']['content_availability'] = this.playEpisodeData.content_availability;
      this.playEpisodeData['recently_watched']['thumbnail'] = this.playEpisodeData.thumbnail;
      this.playEpisodeData['recently_watched']['sec'] = this.playEpisodeData.sec;
      this.playEpisodeData['recently_watched']['min'] = this.playEpisodeData.min;
      this.playEpisodeData['recently_watched']['hours'] = this.playEpisodeData.hours;
      this.playEpisodeData['recently_watched']['genre_text'] = this.playEpisodeData.genre_text;
      this.playEpisodeData['recently_watched']['languages'] = this.playEpisodeData.languages;
      this.playEpisodeData['recently_watched']['synopsis'] = this.playEpisodeData.synopsis;
      this.playEpisodeData['totalSeason'] = Object.keys(this.totalSeason).length;
      this.playEpisodeData['is_preview'] = is_preview;
      this.playEpisodeData['podcast_id'] = this.podcast_details._id;

      this.playEpisodeData.type = 'pc_episode';

      if (this.playEpisodeData.content_availability.toLowerCase() != 'free' && this.playEpisodeData.is_pack_active != 1 && !this.is_preview) {
        setTimeout(() => {
          $('body').css('overflow', 'scroll');
          $('._play_modal_jw').css("visibility", "hidden");
          $('._play_modal_jw').attr('style', 'display: none !important');
        }, 100);
        videojs(oldPl).dispose();
        //this.router.navigate(['subscribe/chooseplan']);
        this.router.navigate(['subscribe/chooseplan'], { queryParams: { 'code': this.playEpisodeData.code } });
      }


      data['is_preview'] = is_preview;
      this.playAnotherTrack(data);
      return;
    }

    if (oldPl) {
      videojs(oldPl).dispose();
    }

    if (is_preview) {
      this.is_preview = true;
      this.is_pause = true;
      this.isAudioExist = false;
      this.currentPlayCode = data.code;
      this.onlyPodcst = true;
      setTimeout(() => {
        $('body').css('overflow', 'scroll');
        $('._play_modal_jw').css("visibility", "hidden");
        $('._play_modal_jw').attr('style', 'display: none !important');
      }, 100);
    } else {
      // this.is_preview = false;
      // this.is_pause = false;
      // this.isAudioExist = true;
      this.currentPlayCode = data.code;
      this.onlyPodcst = true;
      setTimeout(() => {
        $('body').css('overflow', 'scroll');
        $('._play_modal_jw').css("visibility", "visible");
        $('._play_modal_jw').attr('style', 'display: block !important');
      }, 100);
    }

    this.playEpisodeData = data;
    this.playEpisodeData['plan'] = this.isPlan;
    this.playEpisodeData['recently_watched'] = {};
    this.playEpisodeData['recently_watched']['season_id'] = data.season_id;
    this.playEpisodeData['recently_watched']['stop'] = data.stop != undefined ? data.stop : data.seek_bar_data.stop;
    this.playEpisodeData['recently_watched']['duration'] = data.duration != undefined ? data.duration : data.seek_bar_data.duration;
    if (((data.stop != undefined ? data.stop : data.seek_bar_data.stop * 100) / (data.duration != undefined ? data.duration : data.seek_bar_data.duration)) >= 98) {
      this.playEpisodeData['recently_watched']['stop'] = 0;
    }
    this.playEpisodeData['recently_watched']['asset_mongo_id'] = this.playEpisodeData.asset_mongo_id;
    this.playEpisodeData['recently_watched']['charge_code'] = this.playEpisodeData.charge_code;
    this.playEpisodeData['recently_watched']['code'] = this.playEpisodeData.code;
    this.playEpisodeData['recently_watched']['content_availability'] = this.playEpisodeData.content_availability;
    this.playEpisodeData['recently_watched']['thumbnail'] = this.playEpisodeData.thumbnail;
    this.playEpisodeData['recently_watched']['sec'] = this.playEpisodeData.sec;
    this.playEpisodeData['recently_watched']['min'] = this.playEpisodeData.min;
    this.playEpisodeData['recently_watched']['hours'] = this.playEpisodeData.hours;
    this.playEpisodeData['recently_watched']['genre_text'] = this.playEpisodeData.genre_text;
    this.playEpisodeData['recently_watched']['languages'] = this.playEpisodeData.languages;
    this.playEpisodeData['recently_watched']['synopsis'] = this.playEpisodeData.synopsis;
    this.playEpisodeData['totalSeason'] = Object.keys(this.totalSeason).length;
    this.playEpisodeData['is_preview'] = is_preview;
    this.playEpisodeData.type = 'pc_episode';
    this.playEpisodeData['podcast_id'] = this.podcast_details._id;
    if (this.playEpisodeData.content_availability.toLowerCase() != 'free' && this.playEpisodeData.is_pack_active != 1 && !this.is_preview) {
      setTimeout(() => {
        $('body').css('overflow', 'scroll');
        $('._play_modal_jw').css("visibility", "hidden");
        $('._play_modal_jw').attr('style', 'display: none !important');
      }, 100);
      //this.router.navigate(['subscribe/chooseplan']);
      this.router.navigate(['subscribe/chooseplan'], { queryParams: { 'code': this.playEpisodeData.code } });
    } else {
      this.modalService.open(play_episode, { ariaLabelledBy: 'modal-basic-title', windowClass: '_play_modal_jw audio_bar', backdrop: false, keyboard: false }).result.then((result) => {
      }, (reason) => {
      });

    }


  }


  playAnotherTrack(data) {
    console.log('annouce into child component');
    this.assetsService.playTrack(data);

  }

  public checkTrackActivity(activity_d) {
    console.log('yes');
    this.assetsService.podTrackActivity(activity_d);
    this.assetsService.trackActivity(activity_d)
  }

  public onTrackActivity(activity_data) {
    this.assetsService.trackActivity(activity_data);

  }

  stopPreview() {
    this.currentPlayCode = 'test';
    var oldPl = document.getElementById('pl_one');
    setTimeout(() => { videojs(oldPl).dispose(), document.querySelector("ngb-modal-window").classList.remove("show") }, 50);
    // remove player
    this.is_preview = false;
    this.onlyPodcst = false;
    this.is_pause = true;
    this.isAudioExist = true;

    this.modalService.dismissAll();

  }

  public onModalClosed(data: any): void {
    if (data == 'close') {
      this.isAudioExist = false;
      this.is_preview = false;
      this.is_pause = true;
      this.modalService.dismissAll();
      this.assetsService.trackActivity(data)
    }
  }

  pause() {
    if (!this.is_preview) {
      var plyer = document.getElementById('pl_one');
      videojs(plyer).pause();
      this.is_pause = true;
    }

  }

  // play() {
  //   if (!this.is_preview) {
  //     var plyer = document.getElementById('pl_one');
  //     videojs(plyer).play();
  //     this.is_pause = false;
  //     this.checkIsPuse = true;
  //   }

  // }



  public openErrorMsg(err: any) {
    this.paidPackMsg = err;
    this.showErrorMsgModal.nativeElement.click();
  }

  removeErrorBox() {
    var activity_d: any = {
      currentCode: 'no',
      is_pause: true,
      is_podcst: true,
      is_preview: false,
      isAudioExist: true,
    }
    this.checkTrackActivity(activity_d);
    $('body').css('overflow', 'scroll');
    $('._play_modal_jw').css("visibility", "hidden");
    $('._play_modal_jw').attr('style', 'display: none !important');
    this.closeShowErrorMsg.nativeElement.click();
    var oldPlayer = document.getElementById('pl_one');

    // remove player
    if (oldPlayer) {
      videojs(oldPlayer).dispose();
    }
  }

  pad2(number) {
    return (number < 10 ? '0' : '') + number
  }

  getDuration(totalSecond) {

    var hours = Math.floor(totalSecond / (60 * 60));

    var divisor_for_minutes = totalSecond % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);

    // console.log('hours:'+ hours +', minutes:'+minutes+ ', seconds:'+seconds);
    if (hours < 0) {
      hours = 0
    }

    if (minutes < 0) {
      minutes = 0
    }

    if (seconds < 0) {
      seconds = 0
    }

    let fHour = this.pad2(hours);
    let fMinute = this.pad2(minutes);
    let fSecond = this.pad2(seconds);

    if (hours != 0) {
      return fHour + ":" + fMinute + ":" + fSecond;
    } else if (minutes != 0 && seconds != 0) {
      return fMinute + ":" + fSecond;
    } else if (minutes != 0) {
      return fMinute + " Min";
    }
    else {
      return fSecond + " Sec";
    }
  }


}
