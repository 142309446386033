import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ios-faq',
  templateUrl: './ios-faq.component.html',
  styleUrls: ['./ios-faq.component.css','../../../../assets/css/app-style.css']
})
export class IosFaqComponent implements OnInit {
  data: any;
  constructor() { }

  ngOnInit(): void {
    this.data = window.location.href;
    if (this.data.match("dark-theme")) {
      var body = document.getElementById('content');
      body.classList.add("dark");
    } else if (this.data.match("white-theme")) {
      var body = document.getElementById('content');
      body.classList.add("white");
    } else {
      var body = document.getElementById('content');
      body.classList.add("dark");
    }
  }

}
