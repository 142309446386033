import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ChosePlanComponent } from './chose-plan.component';

import { Routes, RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


const routes: Routes = [
    {
        path: '',
        children: [
            { path: '', component: ChosePlanComponent }
        ]
    }
];

@NgModule({
  declarations: [ChosePlanComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule.forChild(routes),
    SharedModule,
    MatExpansionModule,
    NgbModule,
  ],
  exports: [RouterModule],
  providers:[]
})
export class ChosePlanModule { }
