import { AnimationOptions } from "ngx-lottie";

export const successOptions: AnimationOptions = {
  path: "../../../../assets/icon/sucess.json",
  loop: false
}

export const errorOptions: AnimationOptions = {
  path: "../../../../assets/icon/error.json",
  loop: false
}

export const pageNotFoundOptions: AnimationOptions = {
  path: "../../../../assets/icon/404.json",
  loop: true
}

export const serverErrorOptions: AnimationOptions = {
  path: "../../../../assets/icon/500.json",
  loop: true
}

export const redeemCodeOptions: AnimationOptions = {
  path: "../../../../assets/icon/redeem-code.json",
  loop: true
}

export const redeemCodeAppliedOptions: AnimationOptions = {
  path: "../../../../assets/icon/redeem-code-applied.json",
  loop: false
}

export const noActivePlanOptions: AnimationOptions = {
  path: "../../../../assets/icon/no-active-plan.json",
  loop: true
}

export const searchNotFound: AnimationOptions = {
  path: "../../../../assets/icon/no-active-plan.json",
  loop: true
}

export const transactionFailure: AnimationOptions = {
  path: "../../../../assets/icon/transaction-failure.json",
  loop: false
}