import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  razorpayBaseUrl: string = 'https://api.razorpay.com/v1/';
  paymentUrl: string = 'https://stage-payments.digivive.com/pride/charge/';
  paymentBaseUrl: string = 'https://payments.digivive.com/';

  constructor(
    private apiService: ApiService,
    private httpClient: HttpClient,
  ) { }

  private formatErrors(error: any): Observable<never> {
    // Customize error formatting as needed
    return throwError(() => new Error(error));
  }

  getPacksByGroup(): Observable<any> {
    return this.apiService.get('subscription/getpacksbygroup');
  }

  getPaymentModes(): Observable<any> {
    return this.apiService.post('payment_modes');
  }

  getOrderId(checkSum, queryParams): Observable<any> {
    return this.httpClient.get(environment.payment_base_url + 'pride/charge/' + checkSum + '?' + queryParams);
  }

  preBilling(urlEncodedParams: any): Observable<any> {
    return this.httpClient.get(environment.payment_base_url + 'jcocg/SDKCharge/PG/NEXG_SDK?' + urlEncodedParams);
  }
  postBilling(urlEncodedParams: any): Observable<any> {
    return this.httpClient.get(environment.payment_base_url + 'webhook/sdk/razorpay?' + urlEncodedParams);
  }

  createOrder(body: any = {}): Observable<any> {
    // return this.apiService.post(this.razorpayBaseUrl + 'orders', body);
    return this.httpClient.post(this.razorpayBaseUrl + 'orders', body).pipe(catchError(this.formatErrors));
  }

  postGatewayData(request: string, x_verify: any): Observable<any> {
    let request_params:any = {request};
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-VERIFY': x_verify });
    return this.httpClient.post('https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay', request_params,{headers}).pipe(
      catchError(this.formatErrors)
    );
  }

  getPurchaseUrl(urlEncodedParams): Observable<any> {
    return this.apiService.get(`purchaseurl?${urlEncodedParams}`)
  }

  getCouponList(body): Observable<any> {
    return this.apiService.post('couponverification/couponlist', body);
  }

  applyCoupon(queryParams): Observable<any> {
    return this.apiService.post('couponverification?' + queryParams);
  }

  redeemCoupon(body): Observable<any> {
    return this.apiService.post('couponverification/couponRedemption', body);
  }
}
