import { Component, OnInit } from '@angular/core';
import { transactionFailure } from '../../lottie-config';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-transaction-failure',
  template: `
    <div class="d-flex justify-content-center align-items-center">
      <div style="width: 200px">
        <ng-lottie [options]="noActivePlanOptions"></ng-lottie>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class TransactionFailureComponent implements OnInit {
  noActivePlanOptions: AnimationOptions = transactionFailure;


  constructor() { }

  ngOnInit(): void {
  }

}
