import { Component, Input, OnInit } from '@angular/core';
import { errorOptions } from 'src/app/shared/lottie-config';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  errorOptions: AnimationOptions = errorOptions;
  @Input() message: string;

  constructor() { }

  ngOnInit(): void {
  }

}
