import { Component, Input, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { successOptions } from '../../lottie-config';

@Component({
  selector: 'app-success-message',
  template: `
    <div class="success-add">
      <h2 *ngIf="successMsg" style="color:#fff;font-size: 20px;font-weight: 100;line-height: 35px;" class="_verify_t">
        {{successMsg}}
      </h2>
      <div class="lottie-icon-block">
          <div class="lottie-icon">
              <ng-lottie [options]="successOptions"></ng-lottie>
          </div>
      </div>
    </div>
  `,
  styles: [
  ]
})
export class SuccessMessageComponent implements OnInit {

  successOptions: AnimationOptions = successOptions;
  @Input() successMsg: string;

  constructor() { }

  ngOnInit(): void {
  }

}
